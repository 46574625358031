@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Global Css */
.light-theme {
  --primary-color: #2343a4;
  --secondary-color: #f69220;
  --White-Color: #fff;
  --navside-color: #2343a4;
  --Whiteblack-color: #000;
  --body-color: #f3f3f3;
  --card-color: #fff;
  --text-color: #000;
  --link-color: #2343a4;
  --btn1-color: #f69220;
  --btn2-color: #f8b365;
  --heading-size: 16px;
  --para-size: 14px;
  --font-family: "myFirstFont";
  --border-radius: 4px;
  --box-shadow: 0px 0px 5px 3px #00000014;
  --eventicon-color: #f69220;
  --eventiconbg-color: #26baac3d;
  --tablehead-color: #f0f4f9;
  --file-upload: #e9f3fe;
  --img-filter: invert(0%) sepia(90%) saturate(7436%) hue-rotate(108deg)
    brightness(121%) contrast(100%);
  --sidebar: linear-gradient(181deg, #2343a4, #000000);
  --global-search: #fff;
  --global-svg: #000;
  --heading-color: #1b2559;
  --gradient-bcakground-color: linear-gradient(181deg, #2343a4, #000000);
  --table-odd-even: #e9edff;
  --main-color: #fff;
  --editable_ui-color: #e2e2e2;
  --disable_input-color: rgb(0 0 0 / 40%);
  --employeedetail-card: #dae0ff;
  --employeedetail-shadow: #88a6ff;
}

.dark-theme {
  --primary-color: #2343a4;
  --secondary-color: #f69220;
  --White-Color: #fff;
  --navside-color: #151c2c;
  --Whiteblack-color: #fff;
  --body-color: #383f48;
  --card-color: #272c33;
  --text-color: #fff;
  --link-color: #2343a4;
  --btn1-color: #f69220;
  --btn2-color: #f8b365;
  --heading-size: 16px;
  --para-size: 14px;
  --font-family: "myFirstFont";
  --border-radius: 4px;
  --box-shadow: 0px 0px 5px 3px #ffffff0d;
  --eventicon-color: #f69220;
  --eventiconbg-color: #5a5a5a;
  --tablehead-color: #1e1e1e;
  --file-upload: #444444;
  --img-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(40deg)
    brightness(105%) contrast(103%);
  --sidebar: linear-gradient(181deg, #2343a4, #000000);
  --global-search: #272c33;
  --global-svg: #fff;
  --heading-color: #fff;
  --gradient-bcakground-color: linear-gradient(181deg, #2343a4, #000000);
  --table-odd-even: #383f48;
  --main-color: #383f48;
  --editable_ui-color: #4d535d;
  --disable_input-color: rgb(255 255 255 / 50%);
  --employeedetail-card: #000000;
  --employeedetail-shadow: #404551;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid var(--primary-color) !important;
}
.carousel .control-next.control-arrow:before {
  border-right: none !important;
  border-left: 8px solid var(--primary-color) !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.orange_heading_color {
  color: var(--secondary-color);
}
body {
  background-color: var(--body-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: myFirstFont;
  src: url(./components/Fonts/Calibri.ttf);
}

/* scroll bar css start */

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f85931a;
  border-radius: 10px;
}

/* scroll bar css end */
li {
  list-style: square;
  margin-left: 20px;
}

p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 1.5em;
  color: var(--text-color);
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: bold;
  /* padding: 10px 0px; */
  color: var(--text-color);
}

.mainbox {
  padding: 20px;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.m-2 {
  margin: 20px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.m-3 {
  margin: 30px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mr-3 {
  margin-right: 30px;
}

.ml-3 {
  margin-left: 30px;
}

.m-4 {
  margin: 40px;
}

.mt-4 {
  margin-top: 40px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mr-4 {
  margin-right: 40px;
}

.ml-4 {
  margin-left: 40px;
}

.m-5 {
  margin: 50px;
}

.mt-5 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 50px;
}

.mr-5 {
  margin-right: 50px;
}

.ml-5 {
  margin-left: 50px;
}

.text-center {
  text-align: center;
}

.signInSignUp {
  display: none;
}

/* for steps  css  */
.ant-steps-item-content {
  display: none !important;
  min-height: 0px !important;
}
.ant-steps-vertical {
  flex-direction: row !important;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  width: 100%;
  text-align: initial;
}
.ant-steps-horizontal {
  flex-direction: row !important;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  width: 100%;
  text-align: initial;
}
/* for steps  css  */

/* input boxes library  */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 15px !important;
}
.css-lbjakd-MuiStack-root {
  position: absolute;
  z-index: 1 !important;
  top: 0;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-color) !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  background: transparent !important;
}
.Mui-disabled input {
  -webkit-text-fill-color: var(--disable_input-color) !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: unset !important;
  /* background: transparent !important; */
  color: var(--text-color) !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
  color: var(--text-color) !important;
  height: unset !important;
}
.MuiInputBase-root {
  background: transparent !important;
}
.focused .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: var(--primary-color);
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: var(--text-color) !important;
}
.Mui-disabled {
  cursor: no-drop;
  /* background-color: var(--editable_ui-color) !important; */
}
.labelFocused.Mui-focused {
  color: var(--primary-color) !important;
}
/* input boxes library  */
/* new */

.rightboxemail {
  width: 100%;
  text-align: center;
}

/* upload file css  */
.setuploadfile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app_upload {
  display: inline-flex;
  align-items: center;
  border: dotted var(--Whiteblack-color);
  margin-top: 10px;
  justify-content: center;
  background: var(--file-upload);
}
.parent {
  width: 211px;
  /* background: var(--navside-color); */
  border-radius: 8px;
  margin: 9px 8px;
}
.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  position: relative;
  cursor: pointer;
}
.after_file_upload {
  width: 30px;
  filter: var(--img-filter);
}
.file-upload h3 {
  font-size: 11px;
  padding: 0px 0px;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
/* upload file css  */

.emailv {
  background-image: url(./components/photos/email_backgrd.jpg);
  background-size: cover;
  height: 100vh;
  padding-top: 50px;
}
.on_leave {
  /* background: #fff; */
  width: auto;
  margin: 0 auto;
  display: inline-block;
  border-radius: 5px;
  padding: 14px 9px;
  text-shadow: 0px 0px 0px #ccc;
  line-height: 1.5em;
}
.form-description {
  margin: 25px 0px 10px 0px;
  /* color: #666; */
  padding: 20px 0px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
/*Email Verification*/
.allcontent {
  width: 100%;
}

.main {
  /* padding: 10px 40px; */
  flex: 1;
  /* margin-left: 200px; */
  transition: 0.4s;
}

.mainclose {
  /* padding: 10px 40px; */
  flex: 1;
  /* margin-left: 45px; */
  transition: 0.4s;
}

/* global flex properties */
.flex_column_global {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_row_global {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline_flex_column_global {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inline_flex_row_global {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* global flex properties */

/* table style */
tr:nth-child(odd) {
  background-color: var(--table-odd-even);
}

.tablebottomboxleft {
  display: flex;
  align-items: center;
}

.tablebottomboxleft h1 {
  margin-right: 6px;
}

.assign_submit_btn {
  display: flex;
  background-color: var(--navside-color);
  padding-bottom: 10px;
  padding-right: 5px;
  justify-content: flex-end;
}

.pages {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  padding: 8px;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: var(--para-size);
  text-align: left;
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
}

/* table style */

.cusbtn {
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
  padding: 11px 30px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.cusbtn_next {
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
  padding: 10px 40px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 18px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.cusbtn_onboard {
  background: var(--secondary-color);
  color: var(--White-Color);
  padding: 11px 30px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.approvebtn_bulk {
  background: #28a745;
  color: var(--White-Color);
  padding: 11px 30px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.rejectbtn_bulk {
  background: #dc3545;
  color: var(--White-Color);
  padding: 11px 30px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.approve {
  color: #28a745;
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
}

.reject {
  color: #dc3545;
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
}

.pending {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
}
.approvebtn {
  color: #28a745 !important;
  font-size: 13px !important;
  background: #d5ffdf;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
  padding: 2px 10px;
  border-radius: 33px;
}

.rejectbtn {
  color: #dc3545 !important;
  font-size: 13px !important;
  background: #ffced2;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
  padding: 2px 10px;
  border-radius: 33px;
}

.pendingbtn {
  font-size: 13px !important;
  background: #ffe6c9;
  color: var(--secondary-color) !important;
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: right;
  justify-content: end;
  padding: 2px 10px;
  border-radius: 33px;
}

.secondary-btn {
  background: var(--secondary-color);
  color: var(--White-Color);
  padding: 14px 62px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.profile_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilebtn {
  background-color: var(--White-Color);
  color: var(--primary-color);
  padding: 11px 30px;
  cursor: pointer;
  border: 1px dotted var(--primary-color);
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
}

.set_profile_img {
  width: 100px;
  height: 100px;
  border: 1px solid #1b2559;
  border-radius: 50%;
}

.empty_profile_img {
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cusbtn:hover {
  background-color: var(--btn1-color);
  color: #fff;
}

.card {
  background-color: var(--card-color);
  padding: 10px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-right: 10px;
  margin-left: 10px;
}

.cardheading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border-bottom: 1px solid var(--box-shadow); */
}

.cardheading svg {
  color: var(--Whiteblack-color);
}

.cardheading h1 {
  margin-left: 5px;
}

.main-container {
  display: flex;
  /* background-color: var(--body-color); */
}

.pagination {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: end;
}

.pagination span {
  margin-right: 7px;
  color: var(--Whiteblack-color);
}

.pagination strong {
  color: var(--Whiteblack-color);
}

.nonactivebtn {
  background-color: var(--btn2-color);
  color: #fff;
  padding: 7px 10px;
  margin: 0px 1px;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
}

.prvisbtn {
  background-color: var(--btn1-color);
  color: #fff;
  margin: 0px 1px;
  padding: 7px 10px;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
}

main {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: var(--sidebar);
  color: var(--text-color);
  z-index: 9;
  position: sticky;
  top: 0px;
  height: 100vh;
  box-shadow: 3px 0px 5px 0px #0000002e;
  overflow-y: auto;
  padding-bottom: 20px;
}

.top_section {
  padding: 20px 0px 10px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 99;
  background: #1e3a8e;
  /* border-bottom: 1px solid #595959;
  box-shadow: 0px 0px 0px 1px #595959; */
}

.logo {
  font-size: 18px;
    line-height: 0;
    height: 30px;
    width: 143px;
    object-fit: contain;
}

.wizlogo {
  height: 40px;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: var(--White-Color);
  gap: 10px;
  padding: 10px 10px;
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.linkactive {
  display: flex;
  color: #fff;
  gap: 10px;
  background-color: var(--secondary-color);
  padding: 10px 10px;
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  margin: 0px 5px;
  border-radius: var(--border-radius);
}

.link:hover {
  background-color: var(--secondary-color);
  margin: 0px 5px;
  color: #fff;
  border-radius: var(--border-radius);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link_text {
  white-space: nowrap;
  font-size: var(--heading-size);
  font-weight: 500;
  font-family: var(--font-family);
}

/* submenu hover  */
.check {
  display: none;
  /* display: flex; */
  background: var(--card-color);
  /* color: var(--text-color); */
  position: absolute;
  margin-left: 35px;
  padding: 3px 10px;
}

.menu:hover .check {
  display: flex;
}

.hover_link {
  display: flex;
  color: var(--text-color);
  gap: 10px;
  padding: 10px 10px;
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

/* submenu hover  */
.menu {
  display: flex;
  color: var(--White-Color);
  gap: 4px;
  padding: 10px 10px;
  cursor: pointer;
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.menu:hover {
  background-color: var(--secondary-color);
  margin: 0px 3px;
  color: #fff;
  border-radius: var(--border-radius);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.menu_container {
  background: transparent !important;
  color: #000 !important;
}

.menu_item {
  display: flex;
  gap: 5px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  /* padding-left: 20px; */
  /* border-bottom: var(--Whiteblack-color) 0.5px solid; */
}

.menuicon {
  font-size: 22px;
}

.icondrop {
  padding-left: 30px;
}

/* navbar */

.navbar {
  display: flex;
  width: 100%;
  align-items: center;
  height: 60px;
  background-color: var(--navside-color);
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0px 1px 6px -2px #ffffff26;
}

.navbarsbtn {
  color: var(--Whiteblack-color);
  margin-left: 18px;
}

.lefthalfnavbar {
  display: flex;
  width: 50%;
  align-items: center;
}

.searchbar {
  background-color: var(--Whiteblack-color);
  padding: 7px 10px 7px 10px;
  justify-content: center;
  align-items: center;
  border-radius: var(--card-radius);
  display: inline-flex;
  margin-left: 25px;
}

.search {
  border: none;
  outline: none;
}

.righthalfnavbar {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
}

.dp {
  margin-right: 10px;
}

.admin {
  display: inline-flex;
  margin-right: 19px;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.dropdown {
  top: 2.7em;
  background-color: white;
  position: absolute;
  width: 150px;
  box-shadow: 0px 9px 7px -3px #b9b9b9;
  right: 0px;
  border-radius: var(--border-radius);
}

.dropdown::before {
  content: "";
  position: absolute;
  top: -0.7rem;
  right: 1.1rem;
  z-index: -1;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: white;
  border-left: 1px solid gray;
  border-top: 1px solid gray;
}

.dropdown p {
  font-size: 16px;
  color: black !important;
}

.pageicon {
  padding: 6px;
  text-align: left;
}

.pageicon:hover {
  background: #eee;
}

.navbardownarrow {
  color: white;
}

.toggle-theme-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  margin: 10px 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5a5a5a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5a5a5a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleicons {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 10px 5px;
}

/* navbar */

/* footer */
.footer {
  display: flex;
  color: var(--text-color);
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: var(--navside-color);
  border-top: 1px solid var(--box-shadow);
}

/* footer */

/* user dashborad first section progress */

.dash_event_cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventicon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.eventcard:hover .eventicon {
  background: white;
}

.eventcard {
  width: 25%;
  transition: all 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 3px 0px -2px #f6922040;
}

.eventcard p {
  color: var(--text-color);
}

.eventcard:hover {
  background: var(--gradient-bcakground-color);
  border: none;
  color: #fff !important;
  box-shadow: 0 5px 10px rgb(30 32 37 / 28%);
  transform: translateY(calc(-1.5rem / 5));
  -webkit-transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.eventcard:hover p,
.eventcard:hover h1 {
  color: #fff !important;
}

.leave_person_img img {
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.on_leave_dashboard h1 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 600;
}

.leave_person_details {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 300px;
  flex-direction: row;
  width: 100% !important;
  min-width: 100%;
}

.name_designation_person {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.name_designation_person h2,
.name_designation_person p {
  padding: 0px;
  color: #fff;
}

.person_details {
  padding: 15px 15px;
  margin-right: 10px;
  border-radius: 10px;
  flex: 0 0 auto;
  min-width: 150px;
}

.charts {
  width: 99%;
  display: flex;
  justify-content: space-between;
}

.charts_attendance {
  width: 50%;
  flex-direction: column;
  display: flex;
}
.total_chart_attendance {
  width: 98%;
  /* flex-direction: column; */
  /* display: flex; */
}

.charts_employee {
  width: 50%;
  flex-direction: column;
  display: flex;
}

.total_attendance_chart {
  width: 100%;
  height: 500px;
}

.employee_by_dept {
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
}

.name_designation_birthday img {
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.upcoming {
  display: flex;
}

.upcoming_birthday {
  width: 32%;
}

.upcoming_heading h1 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 600;
}

.user_birthday_details {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--card-color);
  /* padding: 20px; */
  padding: 15px 13px;
  box-shadow: var(--box-shadow);
  margin-top: 15px;
  border-radius: var(--border-radius);
}

.name_designation_birthday {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name_designation_birthday div {
  display: flex;
  margin-left: 5px;
  flex-direction: column;
}

.name_designation_birthday h1 {
  color: var(--text-color);
  padding: 0px;
}

.name_designation_birthday p {
  color: var(--text-color);
  padding: 0px;
}

.date_department p,
h3 {
  color: var(--text-color);
}

.upcoming_datils {
  height: 500px;
  overflow-y: auto;
  padding: 5px 11px;
}

.edit_photo_upload {
  background-color: var(--primary-color);
  position: absolute;
  top: 69px;
  /* padding: 4px; */
  border-radius: 100px;
  left: 69px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  object-fit: fill;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* user dashborad first section progress */

/* employee page  */

/* Create Event Popup Start */
.input_box {
  display: flex;
  justify-content: space-between;
}

.input-label {
  width: 100%;
  display: block;
  font-weight: 400;
  color: var(--text-color);
  font-size: var(--para-size);
  font-family: var(--font-family);
}

.custominput {
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  margin: 4px 0px 5px;
  background: transparent;
  color: var(--text-color);
}

.custominput:focus-visible {
  outline: none;
}

.selectoption option {
  padding: 10px 0px;
  margin: 10px 0px;
  background: var(--body-color) !important;
}

.form-error {
  color: #cb0000;
  font-weight: normal;
  font-size: 12px;
}

/* Create Event Popup End */

.formheading {
  border-bottom: 1px dashed var(--text-color);
}

.formbody {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.breadcrumbs_pop {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  justify-content: space-between;
}

/* global search filter  */
.globalsearchbar {
  background-color: var(--White-color);
  padding: 11px 3px 11px 10px;
  /* justify-content: center; */
  box-shadow: 0px 0px 7px -1px #00000087;
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  margin-left: 25px;
}

.globalsearchbar svg {
  color: var(--global-svg);
}

.globalsearchbar input {
  background-color: var(--global-search);
  color: var(--global-svg) !important;
}

/* global search filter  */
/* employee page  */

/* employee edit page  */

.employee_edit_new {
  position: relative;
}
.employee_deta {
  border-left: 3px solid var(--secondary-color);
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
}
.employee_active_deactive {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #078900;
  padding: 4px 10px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3em;
}
.employee_deactive {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  padding: 4px 10px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3em;
}
.employe_base_details {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 2em;
}
.employe_baseaddtional {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}
.employe_baseaddtional span {
  font-size: 14px;
  color: #a5a5a5;
}
.employee_edit_attandancebox {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 2em;
  margin-bottom: 10px;
}
.emp_edit_attandCrad {
  background: var(--employeedetail-card);
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 1.3em;
  box-shadow: 4px 3px 3px 0px var(--employeedetail-shadow);
  flex: 1;
}
.emp_edit_attandCrad_icon {
  padding: 10px;
  background: #fff;
  border-radius: 28px;
  width: 40px;
  height: 40px;
  object-fit: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}
.emp_edit_attandCrad_Para h2 {
  color: var(--primary-color);
  font-size: 20px;
}
.employee_edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employee_name_desi {
  display: inline-flex;
  align-items: center;
  width: 50%;
}

.blue_color {
  color: blue !important;
}

.phone_email {
  display: flex;
  justify-content: space-between;
}

.employee_phone_email {
  width: 50%;
  padding-left: 10px;
  border-left: 2px dashed var(--primary-color);
}

.padding_only {
  font-weight: bold;
  font-size: 18px;
}

.name_designation_empl {
  margin-left: 10px;
}

.name_designation_empl h4 {
  font-size: 14px;
  padding-bottom: 2px;
}

.name_designation_empl h1 {
  font-size: 22px !important;
  margin-bottom: 10px;
}

.name_designation_empl p {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--text-color);
}

.tabs {
  padding: 8px 8px;
  margin-top: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  font-family: "myFirstFont";
}

.tabs svg {
  margin-right: 12px;
}

.tabs:hover {
  display: flex;
  background-color: var(--secondary-color);
  align-items: center;
  color: var(--White-Color) !important;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  border-radius: var(--border-radius);
}

.active-tabs {
  display: flex;
  color: var(--White-Color) !important;
  background-color: var(--secondary-color);
  align-items: center;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  border-radius: var(--border-radius);
}

.content {
  background: var(--card-color);
  /* padding: 10px; */
  display: none;
  overflow: auto;
  width: 100%;
}

.content-regularization {
  display: none;
  overflow: auto;
  width: 100%;
}

.active-content {
  display: block;
}

.tabs_deatils {
  display: flex;
  width: 100%;
}

.bloc-tabs {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.content-tabs {
  width: 80%;
  /* display: flex; */
}

/* employee edit page  */

/* attendance detail page  */
.total_working_hr {
  display: flex;
  justify-content: flex-end;
}

.total_working_hr h1 {
  color: var(--secondary-color);
}

.attendance_info_chart {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.attendance_pie_bar {
  width: 32%;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
}

.chart_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attendancepie {
  height: 395px;
}

.calendar_details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dot {
  width: 6px;
  /* Adjust the size of the dot as needed */
  height: 6px;
  background-color: red;
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make it round */
  display: inline-block;
  /* Place the dot inline with the date */
  margin-left: 2px;
  /* Adjust the spacing between the dot and date */
}

.reddot {
  width: 6px;
  /* Adjust the size of the dot as needed */
  height: 6px;
  background-color: red;
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make it round */
  display: inline-block;
  /* Place the dot inline with the date */
  margin-left: 2px;
  /* Adjust the spacing between the dot and date */
}

.greendot {
  width: 6px;
  /* Adjust the size of the dot as needed */
  height: 6px;
  background-color: #14737a;
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make it round */
  display: inline-block;
  /* Place the dot inline with the date */
  margin-left: 2px;
  /* Adjust the spacing between the dot and date */
}

.orangedot {
  width: 6px;
  /* Adjust the size of the dot as needed */
  height: 6px;
  background-color: #f39213;
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make it round */
  display: inline-block;
  /* Place the dot inline with the date */
  margin-left: 2px;
  /* Adjust the spacing between the dot and date */
}

.browndot {
  width: 6px;
  /* Adjust the size of the dot as needed */
  height: 6px;
  background-color: var(--primary-color);
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make it round */
  display: inline-block;
  /* Place the dot inline with the date */
  margin-left: 2px;
  /* Adjust the spacing between the dot and date */
}

.calendar {
  width: 50%;
}
.react-calendar {
  width: unset !important;
}

.at_office h3 {
  color: var(--secondary-color);
}

.deatils_calendar {
  width: 50%;
  border-left: 1px dotted var(--primary-color);
  display: flex;
  padding-left: 47px;
  flex-direction: column;
}

.at_office {
  display: flex;
  flex-direction: column;
}

.at_office_detail {
  padding-bottom: 10px;
  border-bottom: 1px dotted var(--primary-color);
  display: flex;
  justify-content: space-between;
}

.checkin_checkout {
  display: flex;
  flex-direction: column;
}

.checkin_checkout_heading {
  display: flex;
  justify-content: space-between;
}

.checkin_checkout_heading h3 {
  color: var(--secondary-color);
}

.checkin_checkout_time {
  padding-bottom: 10px;
  border-bottom: 1px dotted var(--primary-color);
  display: flex;
  justify-content: space-between;
}

.attendance_color {
  display: flex;
  justify-content: space-between;
}

.colors_detail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colors_detail p {
  margin-left: 5px;
}

.atten_box {
  box-shadow: 4px 3px 3px 0px var(--employeedetail-shadow);
  background: var(--employeedetail-card);
  padding: 10px 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin: 20px 10px 20px 0px;
}
.atten_box h1 {
  margin: 0px 0px 20px 10px;
}
.atten_det_cardbox {
  display: flex;
  justify-content: space-between;
}
.aten_deta_box {
  padding: 20px;
  text-align: center;
  flex: 1;
}
.aten_deta_box p {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 4px;
}
.aten_deta_box .atten_icon {
  background: #dfe7ff;
  width: 28px;
  height: 26px;
  object-fit: fill;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 2px;
  margin-bottom: 10px;
}
.aten_deta_box h3 {
  font-size: 20px;
  font-weight: bold;
}
/* attendance detail page  */

/* Employee personal page deatils start */

.headingbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingleftbox h1 {
  color: var(--secondary-color);
}

.editbutton {
  background: none;
  cursor: pointer;
  border: none;
  color: var(--secondary-color);
  font-size: 22px;
}

.nocustominputprofile {
  font-size: 14px;
  width: 98%;
  outline: none;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  margin: 4px 0px 5px;
  background: var(--body-color);
  color: var(--text-color);
  cursor: not-allowed;
  font-family: var(--font-family);
}

.custominputprofile {
  font-size: 14px;
  width: 98%;
  outline: none;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  margin: 4px 0px 5px;
  background: transparent;
  color: var(--text-color);
  font-family: var(--font-family);
}

.empl_edit_page_detailboxes {
  display: flex;
  width: 100%;
}
.detailsbox {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* justify-content: space-between; */
  align-items: center;
  /* border-bottom: 1px dashed var(--primary-color); */
  padding-bottom: 5px;
  /* margin-bottom: 20px; */
}

.inputbox {
  width: 100%;
}

.input-label {
  font-weight: bold;
}

.timelinebox {
  display: block;
}

.dependent {
  display: block;
  position: relative;
  padding: 0px 20px 20px;
}
.addtionalareabox {
  flex: 1 1;
}

.dependentcircle {
  background: var(--secondary-color);
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0px;
  left: 14px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 0px 3px #f69220;
}

.depantdetailbox {
  margin-left: 25px;
  margin-top: 3px;
  background: var(--employeedetail-card);
  padding: 20px;
  border-radius: 16px;
  width: 100%;
  position: relative;
}

.depantdetailbox h2 {
  font-size: 20px;
}
.dependent::before {
  background: var(--secondary-color);
  bottom: 0;
  content: "";
  width: 3px;
  position: absolute;
  top: 8px;
  left: 20px;
}

.relationbtn {
  background: var(--primary-color);
  padding: 4px 10px;
  border-radius: 6px;
  color: var(--White-Color) !important;
  font-size: 16px;
  text-transform: capitalize;
}
.addbtn {
  display: flex;
  align-items: center;
}

.cusbtnnew {
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
  padding: 4px 4px 2px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
  color: var(--White-Color);
  padding: 4px 4px 2px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

.depantdetailbox h2,
.depantdetailbox p {
  color: var(--text-color);
}

.set_profile_img_editbtn {
  width: 70px;
  height: 70px;
  border: 1px solid #1b2559;
  border-radius: 50%;
}

.profilecutbtn {
  width: 100%;
  font-size: 14px;
  border: 1px solid #000;
  background: #f0f0f0;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
}

.inputboxprofile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Employee personal page deatils end */
.additonal_profile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2em;
}

.work_educa {
  flex: 1;
}
/*Employee popup button*/
.popupstyle {
  display: block;
  text-align: center;
  margin: 10px auto;
  width: 90%;
}
/*Employee popup button*/

/*Add Employee popup */
.tryy {
  display: flex;
}
.attachicon svg {
  color: var(--text-color);
}
.addEmployeepopup {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;
}
.sidebtn {
  display: flex;
  /* justify-content: center; */
  align-items: flex-end;
}

.attachicon {
  display: flex;
  align-items: center;
}
.dotted_line {
  border-top: dotted;
  border-color: #1d1497;
  border-width: 2px;
  padding-top: 14px;
}
.footerbtn {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;
}
.parastylecolor {
  color: var(--secondary-color);
}
/*Add Employee By bulk*/
.bulk_import_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*Add Employee By bulk*/

/*Add Employee manually*/
.small_iconsstyle {
  color: var(--secondary-color);
  display: contents;
}
.twoBoxesAlign {
  display: flex;
}
.styleimg {
  border-radius: 60%;
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.set_profile_img_manually {
  width: 200px;
  height: 200px;
  border: 1px solid #1b2559;
  border-radius: 50%;
  object-fit: cover;
}
/*Add Employee manually*/

/* additional deatils start */

/* additional deatils start */

/* leave deatils start */
.leave_type {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 300px;
  flex-direction: row;
  min-width: 100%;
  position: relative;
}

.leave_card {
  background-color: var(--card-color);
  padding: 20px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-right: 10px;
  width: 20%;
  margin-left: 10px;
  flex: 0 0 auto;
  margin-top: 20px;
}

.avil_remain {
  display: flex;
  justify-content: space-between;
}

.leave_bal_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_leave {
  background-color: var(--btn1-color);
  color: white;
  padding: 8px 10px;
  margin-top: -40px;
  border-radius: 20px;
}

.leave_history {
  display: flex;
  height: 400px;
  overflow-y: auto;
  flex-wrap: wrap;
  padding-bottom: 20px;
  position: relative;
}

.history_leave {
  display: flex;
  justify-content: space-between;
  width: 47%;
}

.applied_on h1 {
  color: var(--link-color);
}

.applied_on h1 {
  color: var(--link-color);
}

.applied_on h1,
p {
  margin-top: 4px;
}

.approve_rej h1 {
  color: var(--link-color);
}

.approve_rej h1 {
  color: var(--link-color);
}

.approve_rej h1,
p {
  margin-top: 4px;
  /* font-weight: bold; */
}

.approve_rej button {
  margin-top: 4px;
  text-align: right;
}
.flexboxall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  margin-bottom: 10px;
}
.pop_area p {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  font-family: var(--font-family);
}
.pop_area span {
  font-size: 12px;
  color: #7f7f7f;
  font-weight: 400;
  font-family: var(--font-family);
}
.applied_area span {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: bold;
}
.btnareaapp {
  margin: 10px;
  margin-top: 30px;
  text-align: center;
  display: flex;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
}
.btnareaapp button {
  width: 120px;
  text-align: center !important;
  border: none;
  padding: 10px 30px;
  border-radius: var(--border-radius);
  color: #fff;
  font-size: 14px;
  font-family: var(--font-family);
}
.rejectbtnpop {
  cursor: pointer;
  background: #ff6868;
}
.approvebtnpop {
  cursor: pointer;
  background: #095800;
}

.apprej_area {
  margin-top: 30px;
  text-align: right;
}
.apprej_area h4 {
  font-size: 14px;
  line-height: normal;
}
.apprej_area p {
  font-size: 18px;
  color: var(--primary-color);
  font-weight: bold;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 10px;
}
/* leave deatils end */

/* log in page */

.loginbox {
  min-height: 100vh;
  width: 100vw;
  background: var(--card-color);
  box-shadow: 0px 0px 4px 2px #dfdfdf;
  display: flex;
  justify-content: center;
  position: relative;
}

.image {
  display: flex;
  background: var(--gradient-bcakground-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 50%;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome h1 {
  color: white;
  font-size: 25px;
}

.welcome p {
  color: var(--White-Color) !important;
  margin-top: 30px;
}

.about_wiz {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.about_wiz button {
  margin-top: 30px;
}

.img_logo {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.img_logo img {
  width: 50%;
}

.img_logo p {
  color: #fff !important;
}

.form {
  width: 50%;
  background-color: var(--main-color);
}

.formsec {
  background-color: var(--main-color);
}

.sign_up {
  padding: 20px 50px;
  display: flex;
  background-color: #f6f6f6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign_up h1 {
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--heading-color);
  font-size: 35px;
}

.contact_person {
  padding: 0px 30px;
}

.contact_heading h1 {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: bold;
}

.contact_heading {
  display: inline-flex;
  margin-left: 10px;
  border-bottom: 2px dotted var(--secondary-color);
}

/* input box of log in screen  */
.input-container {
  position: relative;
}

.shift_time_row {
  display: flex;
  justify-content: space-between;
}

.shifttime {
  width: 40%;
  position: relative;
}

.loginlabel {
  position: absolute;
  left: 10px;
  top: -12px;
  font-weight: bold;
  transition: all 0.2s;
  padding: 0 2px;
  z-index: 1;
  background-color: var(--card-color);
  color: var(--text-color);
}

.customTextField {
  width: 200px; /* Set the desired width */
  height: 40px; /* Set the desired height */
}
.text_input {
  padding: 0.8rem;
  width: 100%;
  /* height: 20px; */
  /* border: 2px solid var(--heading-color); */
  border: 1px solid var(--primary-color);
  background: transparent;
  border-radius: 5px;
  font-size: 18px;
  outline: none;
  transition: all 0.3s;
  /* position: relative; */
}

.loginlabel::before {
  content: "";
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.text_input:focus {
  border: 1px solid var(--primary-color);
  z-index: 3;
}

.text_input:focus + .loginlabel,
.filled {
  top: -25px;
  color: var(--heading-color);
  font-size: 18px;
  z-index: 9;
}

.loginlabel:target + .loginlabel {
  top: -10px;
  color: var(--heading-color);
  font-size: 14px;
}

.text_input::placeholder {
  opacity: 1;
  animation-delay: 0.2s;
}

.role_input {
  position: relative;
}

.padding_role {
  padding: 0px 30px;
}

/* input box of log in screen  */

.forgetpass {
  padding: 0px 30px;
}

.forgetpass input {
  margin-right: 5px;
}

.forgetpass span {
  color: var(--eventicon-color);
}

.forgetpass p {
  color: var(--heading-color);
  font-size: 15px;
  font-weight: bold;
}

.remember_me {
  display: flex;
  align-items: center;
}

.nextbtn {
  display: flex;
  justify-content: end;
  padding: 0px 30px;
}

.next_prev_btn {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  gap: 2em;
}

.state_pincode_div {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
}

.state_city_pincode {
  position: relative;
  width: 30%;
}

.branch_div {
  padding: 0px 30px;
}

.branch {
  position: relative;
  width: 100%;
}

.select_department {
  padding: 0px 30px;
  flex-wrap: wrap;
  display: flex;
}

.week_days {
  padding: 12px 30px;
  justify-content: space-between;
  display: flex;
  width: 50%;
}

.select {
  display: flex;
  width: 25%;
  margin: 10px 0px;
}

.select_week {
  display: flex;
  flex-direction: column;
}

.select_week p {
  font-size: 16px;
  font-weight: bold;
  color: var(--heading-color);
}

.week_heading h1 {
  /* padding: 0px 30px; */
  color: var(--heading-color);
  font-size: 20px;
  font-weight: bold;
}

.week_heading {
  display: inline-flex;
  padding: 0px 30px;
  /* border-bottom: 2px dotted var(--heading-color); */
}

.select p {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--heading-color);
}

.check_success {
  text-align: center;
  margin: 5em 0px;
  display: flex;
  flex-direction: column;
  margin: 3em auto;
  justify-content: center;
  align-items: center;
  height: calc(64vh - 50px);
  max-height: 100%;
}

.check_success h1 {
  font-size: 45px;
  margin: 10px;
  color: var(--primary-color);
}

.check_success p {
  color: var(--modalheader-color);
}

.checkicon {
  background: var(--btn1-color);
  padding: 10px;
  border-radius: 7em;
}

/* log in page */

/* pop up */
.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.modal {
  z-index: 999;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  border-radius: 10px;
  max-width: 600px;
  min-width: 450px;
}

.close-modal {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  padding: 5px 7px;
  background: none;
  border: none;
  color: var(--White-Color);
  font-size: 22px;
}

.close-modal svg {
  color: var(--White-Color);
}

.modal-header {
  background: var(--gradient-bcakground-color);
  padding: 11px 10px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
  color: var(--White-Color);
  font-size: var(--heading-size);
}

.modal-body {
  background-color: var(--card-color);
  padding: 20px 20px;
  height: 100%;
  max-height: 520px;
  overflow: auto;
}

.modal_body_assign {
  background-color: var(--card-color);
  padding: 5px 10px;
  height: 100%;
  max-height: 560px;
  overflow: auto;
}

.modal_content_guest {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  border-radius: 10px;
  width: 60%;
  min-width: 450px;
}

/*  pop up */

/* sign up  */
.input-container-sign-up {
  position: relative;
  margin-left: 4px;
  width: 100%;
}
.input_boxes {
  display: flex;
  justify-content: space-between;
  padding: 0px 28px;
}

.left_boxes {
  width: 50%;
  flex: 1;
  margin-right: 10px;
}

.right_boxes {
  width: 50%;
}

.ant-steps-item-container {
  display: grid;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-steps
  .ant-steps-item-title::after {
  top: -16px !important;
  left: 46px !important;
  height: 3px !important;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #2343a4 !important;
  border-color: #2343a4 !important;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: var(--secondary-color) !important;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

:where(.css-dev-only-do-not-override-fpg3f5).ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: var(--White-Color) !important;
}

.input_container_sign_up {
  position: relative;
  width: 100%;
}

.check_success_sign_up {
  text-align: center;
  margin: 5em 0px;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  margin: 3em auto;
  justify-content: center;
  align-items: center;
  /* height: calc(64vh - 50px); */
  max-height: 100%;
}

.remember {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.remember p {
  color: var(--heading-color);
  font-weight: bold;
}

.remember_me p {
  margin-left: 5px;
  margin-top: 0px;
}

/* sign up  */

.breadcrumbbtn {
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
  padding: 8px 10px 6px;
  border-radius: var(--border-radius);
  border: none;
  cursor: pointer;
}

/* attendance page  */
.attendace_radio {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.radio_btn {
  margin-left: 15px;
}

.radio_btn label {
  font-weight: bold;
  color: var(--text-color);
}

/* .radio_btn span {
  margin-left: 5px;
} */

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 4px solid var(--primary-color);
  border-radius: 50%;
  margin-right: 5px;
  background-color: transparent;
  position: relative;
  top: 6px;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.8s;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
  }

  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #1d1497;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #fbae49;
  }

  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: var(--secondary-color);
  }
}

.card_att {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-right: 10px;
  margin-left: 10px;
}

/* company settings pages */
.company_edit {
  display: flex;
  justify-content: flex-end;
}
.setting_input_boxes {
  display: flex;
}

.setting_input_boxes .input-container {
  flex: 1;
  margin: 0px 10px;
}

/* attendance page  */
.butnsec {
  text-align: right;
  margin-top: 20px;
}

/* localization */

.disabledinput:disabled {
  background: #d2d2d2;
  color: #000;
  border: none;
}

.disblelable {
  background: none;
}

/* Brach Page Start */
.reporting_manager {
  width: 32% !important;
}
.branchbox {
  display: flex;
  flex-wrap: wrap;
}

.brachcard {
  width: 24%;
  text-align: center;
  margin: 20px 4px;
}

.branchiconbox {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: var(--secondary-color);
  color: #fff;
  padding: 13px 0px 0px;
  margin-top: -40px;
  border-radius: 36px;
  margin-bottom: 6px;
}

/* Department  */
.departbox {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.employeebox {
  text-align: right;
}

.employeebox span {
  font-style: italic;
  font-size: 12px;
}

/* manger page setting */

.mangerbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
}

.mangerboxicon svg {
  background: var(--secondary-color);
  padding: 12px;
  width: 50px;
  height: 50px;
  border-radius: 6px;
  color: #fff;
}

.mangerboxright {
  text-align: right;
}

.mangerdepart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mangerarea {
  text-align: left;
}

.stylenonebtn {
  background: no-repeat;
  border: none;
  cursor: pointer;
}

/* Shift page setting */

.Shiftcard {
  min-width: 48%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  width: 48%;
  margin: 4px 4px;
}

.shiftbox {
  display: flex;
  align-items: center;
}

.shiftleftarea {
  margin-right: 10px;
  width: 70px;
  text-align: center;
}

.shiftbag {
  background: var(--secondary-color);
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 34px;
  color: var(--White-Color);
}

.shiftuserslist {
  display: flex;
}

.list svg {
  background: var(--primary-color);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #ffffff;
  margin-left: -10px;
  border: 1px solid var(--secondary-color);
  object-fit: cover;
  padding: 4px;
}
.list img {
  background: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  margin-left: -10px;
  border: 1px solid var(--primary-color);
  object-fit: cover;
}
.addbtnshift p {
  padding: 0px 10px;
  font-size: 18px;
  font-weight: 500;
}

.addbtnshift svg {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-left: -10px;
  border: 2px solid var(--secondary-color);
  background: var(--primary-color);
  color: var(--White-Color);
}

.shourbox {
  text-align: center;
  background: #f692204f;
  color: var(--secondary-color);
  border-radius: 10px;
}

.shiftrightarea {
  flex: 1;
}

.shiftfulldetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.workhourshift {
  border-right: 1px solid var(--primary-color);
  padding-right: 20px;
  padding-left: 20px;
}
.workhourshift:first-child {
  padding-left: 0px;
}

.workhourshift:last-child {
  border-right: none;
}

.shift_input_boxes {
  display: flex;
}

.shift_input_boxes .input-container {
  flex: 1;
}

.week_days_shift {
  padding: 15px 0px;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.checklatebybox {
  padding: 5px 6px;
  width: 40px;
  margin: 0px 6px;
  border-radius: 4px;
  border: 2px solid var(--primary-color);
}

.shiftlateby p {
  margin: 10px 0px;
}

/* onboard pages style  */
.onboard_multiple {
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
}
.multi_file_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdf_names {
  display: flex;
  align-items: center;
}
.multi_file_scroll {
  width: 49%;
}
.multi_file_upload {
  display: flex;
  justify-content: space-between;
}
.for_scroll_only {
  overflow-y: auto;
  height: 248px;
}
.drag_submit {
  display: flex;
  justify-content: flex-end;
}
.docs {
  border: 1px solid var(--primary-color);
  display: inline-flex;
  padding: 6px 26px;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 20px;
}
.downl_up {
  display: flex;
  justify-content: space-between;
}

.modal_content_large {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  border-radius: 10px;
  max-width: 768px;
  width: 100%;
  min-width: 450px;
}

.multi_rows {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* onboard pages style  */

/* onboard settings  */
/* upload file css  */
.setuploadfile {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.app {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  background: transparent;
  width: 60%;
  border: 2px dashed var(--primary-color);
  border-radius: var(--border-radius);
}

.file-upload {
  text-align: center;
  position: relative;
  cursor: pointer;
  border: none;
  width: 100%;
}

.file-upload svg {
  color: #e9edff;
}

.after_file_upload {
  width: 30px;
  filter: var(--img-filter);
}

.file-upload h3 {
  font-size: 18px;
  padding: 0px 0px;
}

.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.docs {
  cursor: pointer;
  /* padding: 10px; */
}

.docs.clicked {
  background-color: blue; /* Change to whatever color you want */
  color: white !important;
}
.docs.clicked p {
  color: white !important;
}

/* upload file css  */
/* onboard settings  */

/* invioce settings */
.inputinvioceprof {
  display: flex;
}

.inviocesettingprof {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  width: 130px;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  margin: 4px 0px 5px 10px;
  height: 40px;
}

.inviocesettingprof img {
  overflow: hidden;
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 0px;
}

/* Salary Settings */
/* ToggleButton.css */
.switch-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-toggle label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
}

.switch-toggle label:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  left: 4px;
  top: 4px;
  background-color: #fff;
  transition: 0.4s;
}

.switch-toggle input:checked + label {
  background-color: #4caf50;
}

.switch-toggle input:checked + label:after {
  transform: translateX(26px);
}

.toggleboxhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggleboxarea {
  border-bottom: 1px dashed var(--primary-color);
  padding: 10px 0px;
}

.tdsform .multi_rows .input-container {
  width: 100%;
}

.tdsform .multi_rows svg {
  width: 20%;
}

/* Attendance settings */

.attendancearea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--primary-color);
  padding-bottom: 15px;
}
.attendace_Checkarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attendace_Checkarea .radio_btn span {
  font-size: 14px;
  font-weight: normal;
}
.yescheckbox {
  display: flex;
  align-items: center;
}
.yescheckbox input {
  width: 20px;
  height: 20px;
}
.lateselect select {
  width: 100%;
  background: none;
  border: none;
}
.lateselect input {
  width: 40%;
}

/* overview page  */
.overview {
  display: flex;
  justify-content: space-between;
}
/* overview page  */

/* salary details */
.salary_details {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--primary-color);
  justify-content: space-between;
}
.salary_details h1 {
  font-size: 35px;
}
.salary_details p {
  font-size: 18px;
}
.breakup_salary_details {
  display: flex;
  border-bottom: 1px dashed var(--primary-color);
  padding-bottom: 10px;
  justify-content: space-between;
}
.monthly_annual {
  display: flex;
  width: 42%;
  justify-content: space-between;
}
/* salary details */

/*Payroll add salry of staff*/
.lower_input_sty {
  color: var(--secondary-color);
}
.btnstyle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Payroll add salry of staff*/

/*Email Verification*/
.emailVerify {
  display: flex;
}

.image_loginscreen {
  display: flex;
  background: var(--gradient-bcakground-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
}
.rightContentStyle {
  display: flex;
  flex-direction: column;
}

/* leave type page */
.leavetypesty {
  display: flex;
  justify-content: space-between;
  margin-right: 1%;
}
.secondWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerbuttonplus h1 {
  color: var(--secondary-color);
  margin-left: 1%;
}
.footerbuttonplus {
  display: flex;
  margin-top: 2%;
}

/* leave type page */

/* leave setting page  */

.inputboxleave {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputbocandh2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightsty {
  width: 15%;
}

.wfhinputbox input {
  width: 8% !important;
}

.wfhinputbox {
  justify-content: flex-end !important;
}
/* leave setting page  */
/* holiday page */
.dropdownsty {
  background: var(--gradient-bcakground-color);
  color: var(--White-Color);
  padding: 11px 30px;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}
.leftupperpartbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bgg {
  background: #2343a4;
}
.conditionaldropdownsty {
  color: var(--text-color);
  border: 1px solid var(--text-color);
  padding: 11px 30px;
  cursor: pointer;
  border-radius: var(--border-radius);
  background: none;
  font-size: 14px;
  font-weight: normal;
  font-family: "myFirstFont";
}

/* acl CSS starts */
.thirdsidetabsty h1 {
  font-size: large;
}
.sidetab {
  border: 1px #2343a4 solid;

  padding: 8px;
  width: 18% !important;
}
.rightcontent {
  padding-left: 3%;
}

.content-tabsright {
  width: 80%;
}
.checkboxestxt {
  display: flex;
  justify-content: space-between;
  color: black;
}
.checkboxestxt h1 {
  color: black;
}
.belowsty {
  margin-top: 5%;
}

.submitbtnroleandper {
  text-align: right;
  margin-top: 8%;
}
/* asl CSS ends */

/* document upload  */
.bulk_document_upload {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed var(--primary-color);
  padding-bottom: 15px;
}
.upload_document {
  display: flex;
  flex-direction: column;
}
/* document upload  */

/* media queries  */
@media (max-width: 540px) {
  .resposive_table {
    overflow: auto;
    width: 100%;
  }
  .pages {
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: center;
    justify-content: start;
    gap: 1em;
  }
  .globalsearchbar {
    margin-left: 0px;
    width: 100%;
  }

  .image {
    display: none !important;
  }
  .form {
    width: 100%;
    padding-bottom: 10px;
  }
  .remember p {
    margin-top: 0px;
  }
  .signInSignUp {
    display: block;
    margin-bottom: 5px;
  }
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 14.5px 14px !important;
  }
  .MuiSvgIcon-root {
    font-size: 1rem !important;
  }
  .ant-steps-item-tail {
    display: none !important;
  }
  .ant-steps-item-icon {
    margin: 0 auto !important;
  }
  .remember_me {
    align-items: flex-start;
  }
  .forgetpass input {
    margin-top: 5px;
  }
  .nextbtn {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .input-container-sign-up {
    margin-top: 10px;
  }
  .input_boxes {
    flex-direction: column;
  }
  .state_pincode_div {
    flex-direction: column;
  }
  .state_city_pincode {
    width: 100%;
  }
  .left_boxes {
    width: 100%;
  }
  .right_boxes {
    width: 100%;
  }
  .cusbtn_next {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .brachcard {
    width: 31%;
  }
  .reporting_manager {
    width: 48% !important;
  }
  .aten_deta_box {
    width: 20%;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .atten_det_cardbox {
    gap: 0.4em;
  }
}
@media (max-width: 1024px) {
  .history_leave {
    width: 100%;
  }
  .Shiftcard {
    width: 100%;
  }
  .brachcard {
    width: 48%;
  }
  .reporting_manager {
    width: 48% !important;
  }
  .aten_deta_box {
    width: 20%;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .atten_det_cardbox {
    gap: 0.4em;
  }
}
@media (min-width: 200px) and (max-width: 1000px) {
  .reporting_manager {
    width: 100% !important;
  }
}
@media (max-width: 820px) {
  .leftupperpartbtn {
    display: flex;
    flex-direction: column;
  }
  .cusbtnres {
    width: 100% !important;
  }
  .dropdownstyres {
    width: 100% !important;
    margin-right: 0px !important;
    margin-top: 8px !important;
  }
}
/* media queries  */

/* experiment */

.ant-steps-horizontal,
.ant-steps-vertical {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.88);
  display: flex;
  flex-direction: row !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-align: initial;
  width: 100%;
}

.ant-steps.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}

.ant-steps .ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}

.ant-steps.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto !important;
  padding-inline-start: 0;
  overflow: visible;
}
.ant-steps .ant-steps-item-container {
  outline: none;
}
.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  inset-inline-start: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps .ant-steps-item-icon,
:where(.css-1drr2mu).ant-steps .ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps .ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: 0;
  margin-inline-end: 8px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #1677ff;
  border-color: #1677ff;
}
.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-inline-end: 16px;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps .ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #1677ff;
  line-height: 1;
}
.ant-steps .ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-inline-start: 0;
  overflow: visible;
}
.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  inset-inline-start: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps .ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: 0;
  margin-inline-end: 8px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: rgba(0, 0, 0, 0.06);
  border-color: transparent;
}
.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-inline-end: 16px;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps .ant-steps-item-icon {
  margin: 0 auto !important;
}
.ant-steps .ant-steps-item:last-child {
  flex: 1 1;
}

/*sweet alert classes*/

 /* Custom class for the icon */
  .custom-icon .swal-icon--error {
    background-color: #ff0000 !important; /* Change icon background color */
  }

  /* Custom class for the button */
  .custom-button .swal-button {
    background-color: #0000ff !important; 
    color: #ffffff !important;
  }

.swal-button--custom {
  background-color: #f69220 !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.swal-button {
  background-color: #f69220 !important;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.swal-text {
  font-size: 18px;
  position: relative;
  float: none;
  line-height: normal;
  vertical-align: top;
  text-align: left;
  display: inline-block;
  margin: 0;
  padding: 0 10px;
  font-weight: 500;
  color: #2343a4;
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.swal-title {
  color: #2343a4;
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}

.listitem_content_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  padding: 10px 20px;
}
.listmagerbox{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.listmangerbox_icon svg{
  background: var(--secondary-color);
    padding: 12px;
    width: 50px;
    height: 50px;
    border-radius: 40px;
    color: #fff;
}
.listitemmagerdept{
  font-size: 14px;
    font-weight: 500;
    background: #f692204a;
    padding: 7px 10px;
    border-radius: 3px;
    color: #393939;
}
.listitemactions{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.listitememployee{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;

}
.iconsstyle{
  height: 28px;
  width: 38px;  
}

.active-status-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.active1 {
  background-color: green;
}

.inactive1 {
  background-color: red;
}
.headingrightbox{
  display: flex;
  gap: 15px;
}

.loading-fallback {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 61, 0, 0.2) 33%, #ff3d00 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loading-fallback::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: white;
}
@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg)}
} 
.loaderparent{
  text-align: center;
}

.profile_upload {
  position: relative;
}
.adminprofilebtn{
  position: absolute;
    top: 9px;
    left: 60%;
    right: 0;
    /* bottom: 19px; */
    width: 30px;
    height: 30px;
    background: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 20px;
}
.profileinfodata{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.profileinfodata span{
  font-size: 16px;
  color: #757575;
}
.profileinfodata p{
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color);
}
.adprofiletitle{
  color: var(--primary-color);
}